<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn
        block
        color="secondary"
        @click="onShowDialog"
        v-on="on"
        data-test="activateButton"
        data-cy="user-profile-edit-button"
      >
        {{ companyRequest ? "Request to join a Company" : "Edit Profile Info" }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-5">
        <span class="headline font-weight-bold primary--text">{{
          companyRequest ? "Request to join a Company" : "Edit Profile Info"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <small class="red--text">* indicates required field</small>
          </v-row>
          <v-form lazy-validation ref="form">
            <v-row v-show="!companyRequest" dense>
              <v-col cols="12">
                <v-text-field
                  label="Username *"
                  name="username"
                  type="text"
                  autocomplete="off"
                  v-model="formUserName"
                  :rules="rules.requireCheck"
                  data-cy="user-profile-user-name"
                />
              </v-col>
            </v-row>
            <v-row v-show="!companyRequest" dense>
              <v-col cols="12">
                <v-text-field
                  label="Email *"
                  name="email"
                  type="text"
                  autocomplete="off"
                  v-model="formEmail"
                  disabled
                />
              </v-col>
            </v-row>
            <v-row v-show="!companyRequest" dense>
              <v-col cols="12">
                <v-text-field
                  label="Full Name *"
                  name="fullName"
                  type="text"
                  autocomplete="off"
                  v-model="formFullName"
                  :rules="rules.requireCheck"
                  data-cy="user-profile-full-name"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  label="Company Request *"
                  name="company_request"
                  type="text"
                  autocomplete="off"
                  v-model="formCompanyRequest"
                  :rules="rules.requireCheck"
                  data-cy="user-profile-company-request"
                />
              </v-col>
            </v-row>
            <v-checkbox
              v-model="formNdaRequest"
              label="Request NDA"
              color="secondary"
              dense
              hide-details
              class="pb-3 caption"
              data-cy="user-profile-nda-checkbox"
            ></v-checkbox>
          </v-form>
        </v-container>
        <v-divider />
        <v-row class="ma-2">
          <v-btn
            color="warning"
            @click="onClose"
            data-test="cancelButton"
            data-cy="user-profile-cancel-button"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="onSave"
            data-test="saveButton"
            data-cy="user-profile-save-button"
            >Save</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "EditProfileForm",
  props: {
    projectUser: {
      type: Object,
      default: null,
    },
    companyRequest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formUserName: null,
      formEmail: null,
      formFullName: null,
      formCompanyRequest: null,
      formBillingAddress: null,
      formShippingSame: null,
      formShippingAddress: null,
      formNdaRequest: null,
      dialog: false,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
        emailCheck: [
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Email is invalid";
          },
        ],
      },
    };
  },
  methods: {
    shippingAddressRules() {
      const rules = [];
      if (!this.formShippingSame) {
        const rule = (v) => !!v || "Input is required";
        rules.push(rule);
      }
      return rules;
    },
    onShowDialog() {
      this.formUserName = this.projectUser ? this.projectUser.username : null;
      this.formEmail = this.projectUser ? this.projectUser.email : null;
      this.formFullName = this.projectUser ? this.projectUser.full_name : null;
      this.formCompanyRequest = this.projectUser
        ? this.projectUser.company_request
        : null;
      this.formBillingAddress = this.projectUser
        ? this.projectUser.billing_address_request
        : null;
      this.formShippingSame = this.projectUser
        ? this.projectUser.shipping_same_as_billing_request
        : null;
      this.formShippingAddress = this.projectUser
        ? this.projectUser.shipping_address_request
        : null;
      this.formNdaRequest = this.projectUser
        ? this.projectUser.nda_request
        : null;
    },
    onClose() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const actionPayload = {
        id: this.projectUser.pk,
        data: {
          username: this.formUserName,
          email: this.formEmail,
          full_name: this.formFullName,
          company_request: this.formCompanyRequest,
          billing_address_request: this.formBillingAddress,
          shipping_same_as_billing_request: this.formShippingSame,
          shipping_address_request: this.formShippingSame
            ? ""
            : this.formShippingAddress,
          nda_request: this.formNdaRequest,
        },
      };
      this.$emit("saveData", actionPayload);
      this.dialog = false;
    },
  },
};
</script>
